import { User } from "../interfaces/User";

export const isSuperAdmin = (user: User | null) => {
    let isSuperAdmin = false;

    if (user && user.roles.some(r => r === "superAdmin")) isSuperAdmin = true;

    return isSuperAdmin;
};

export const isAtLeastClientAdmin = (user: User | null) => {
    let isAtLeastClientAdmin = false;

    if (user && (user.roles.some(r => r === "clientAdmin") || isSuperAdmin(user))) isAtLeastClientAdmin = true;

    return isAtLeastClientAdmin;
};

export const isAtLeastSiteAdmin = (user: User | null) => {
    let isAtLeastSiteAdmin = false;

    if (user && (user.roles.some(r => r === "siteAdmin") || isAtLeastClientAdmin(user) || isSuperAdmin(user))) isAtLeastSiteAdmin = true;

    return isAtLeastSiteAdmin;
};

export const isClientAdmin = (user: User | null) => {
    let isClientAdmin = false;

    if (user && user.roles.some(r => r === "clientAdmin")) isClientAdmin = true;

    return isClientAdmin;
};

export const isSiteAdmin = (user: User | null) => {
    let isSiteAdmin = false;

    if (user && user.roles.some(r => r === "siteAdmin")) isSiteAdmin = true;

    return isSiteAdmin;
};

export const getHighestRole = (user: User | null) => {
    if (user) {
        if (user.roles.some(r => r === "superAdmin")) return "superAdmin";
        if (user.roles.some(r => r === "clientAdmin")) return "clientAdmin";
        if (user.roles.some(r => r === "siteAdmin")) return "siteAdmin";
    }
    return undefined;
};

export const displayRole = (role: string | undefined) => {
    switch (role) {
        case "superAdmin":
            return "Super Administrateur";
        case "clientAdmin":
            return "Administrateur client";
        case "siteAdmin":
            return "Administrateur de site";
        default:
            return "";
    }
};
